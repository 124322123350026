<template>
  <div>
    <a-modal v-model="visible" width="750px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑产品信息" : "新增产品信息" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }"
        >
          <a-row gutter="12">
            <a-divider orientation="left" id="basic-information"> 基本信息 </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="产品名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="产品编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="barcode" label="条形码">
                <a-input v-model="form.barcode" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="spec" label="规格">
                <a-input v-model="form.spec" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="drawing_number" label="尺寸">
                <a-input v-model="form.drawing_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="category" label="分类">
                <a-select v-model.number="form.category" style="width: 100%" :allowClear="true">
                  <a-select-option v-for="item of classificationItems" :key="item.id" :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model.number="form.unit" :allowClear="true">
                  <a-select-option v-for="item of unitItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="editable_batch_number" label="启用批次控制">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.editable_batch_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_shelf_life" label="启用保质期">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.enable_shelf_life" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_shelf_life" :span="24" :md="12">
              <a-form-model-item prop="shelf_life_days" label="保质期天数">
                <a-input v-model="form.shelf_life_days" suffix="天" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_shelf_life" :span="24" :md="12">
              <a-form-model-item prop="shelf_life_warning_days" label="保质期预警天数">
                <a-input v-model="form.shelf_life_warning_days" suffix="天" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="delivery_days" label="交期(天)">
                <a-input-number v-model="form.delivery_days" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="是否激活产品">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="source" label="来源">
                <a-select v-model="form.source" mode="tags" style="width: 100%">
                  <a-select-option value="采购">采购</a-select-option>
                  <a-select-option value="自产">自产</a-select-option>
                  <a-select-option value="外协加工">外协加工</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_inventory_warning" label="启用库存警告">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.enable_inventory_warning" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number v-model="form.inventory_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number v-model="form.inventory_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row v-if="enablePurchaseManage || enableSalesManage" gutter="12">
            <a-divider orientation="left" id="price-management"> 价格管理 </a-divider>
            <a-col v-if="enablePurchaseManage" :span="24" :md="12">
              <a-form-model-item prop="purchase_price" label="采购价(元)">
                <a-input-number v-model="form.purchase_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="enableSalesManage" :span="24" :md="12">
              <a-form-model-item prop="retail_price" label="普通零售价(元)">
                <a-input-number v-model="form.retail_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="enableSalesManage" :span="24" :md="12">
              <a-form-model-item prop="level_price1" label="等级一价(元)">
                <a-input-number v-model="form.level_price1" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="enableSalesManage" :span="24" :md="12">
              <a-form-model-item prop="level_price2" label="等级二价(元)">
                <a-input-number v-model="form.level_price2" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="enableSalesManage" :span="24" :md="12">
              <a-form-model-item prop="level_price3" label="等级三价(元)">
                <a-input-number v-model="form.level_price3" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="graphic-information"> 图文信息 </a-divider>
            <a-col :span="24" :md="24">
              <a-upload
                action="/api/goods_images/"
                list-type="picture-card"
                :headers="{ 'X-CSRFToken': Cookies.get('csrftoken') }"
                :file-list="form.image_items"
                @preview="handlePreview"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="file"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-col>
            <a-col :span="24" :md="24">
              <a-textarea placeholder="产品详细介绍" :rows="4" v-model="form.description" />
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="beginning-inventory"> 库存信息 </a-divider>
            <a-col :span="24" :md="24" style="max-height: 480px; overflow-y: auto">
              <a-table
                rowkey="id"
                size="small"
                :columns="columns"
                :data-source="warehouseItems"
                :loading="loading"
                :pagination="false"
                style="width: 100%"
              >
                <div slot="total_quantity" slot-scope="value, item">
                  <div v-if="form.enable_batch_control">
                    <a-input :value="item.total_quantity ?? 0" disabled style="width: 75%" />
                    <a-button icon="plus" @click="chooseBatch(item)"></a-button>
                  </div>
                  <a-input-number
                    v-else
                    :value="item.total_quantity"
                    min="1"
                    @change="(value) => changeQuantity(value, item)"
                  />
                </div>
              </a-table>
            </a-col>
          </a-row>
          <!-- <a-row gutter="12">
            <a-divider orientation="left" id="inventory-warning"> 库存预警 </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_inventory_warning" label="启用库存警告">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.enable_inventory_warning" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number v-model="form.inventory_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number v-model="form.inventory_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row> -->
        </a-form-model>
      </div>
      <a-modal
        :title="batchTitle"
        v-model="batchVisible"
        width="750px"
        cancelText="关闭"
        :maskClosable="false"
        @cancel="batchVisible = false"
        @ok="confirmChoosed"
      >
        <div style="margin-bottom: 16px">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addLine">添加</a-button>
        </div>
        <a-table
          rowkey="id"
          size="small"
          :columns="columnsBatch"
          :data-source="batchItems"
          :pagination="false"
          style="width: 100%"
        >
          <div slot="remain_quantity" slot-scope="value, item">
            <a-input-number
              :value="item.remain_quantity"
              @change="(value) => changeQuantityBatch(value, item, 'remain_quantity')"
            />
          </div>
          <div slot="number" slot-scope="value, item">
            <a-input :value="item.number" @change="(e) => changeQuantityBatch(e, item, 'number')" />
          </div>
          <div slot="production_date" slot-scope="value, item">
            <a-date-picker
              :value="item.production_date"
              valueFormat="YYYY-MM-DD"
              @change="(value) => changeQuantityBatch(value, item, 'production_date')"
            />
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button icon="minus" @click="removeLine(item)"></a-button>
          </div>
        </a-table>
      </a-modal>
    </a-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { goodsInformationCreate, goodsInformationUpdate } from "@/api/goods";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "FormModal",
  props: ["visible", "form", "warehouseItems", "classificationItems", "unitItems"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      Cookies,
      batchTitle: "管理批次",
      batchVisible: false,
      loading: false,
      columns: [
        {
          title: "仓库",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "库存数量",
          dataIndex: "total_quantity",
          key: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
      ],
      isKeepAdd: false,
      categoryForm: {},
      categoryVisible: false,
      rules: {
        name: [{ required: true, message: "请输入产品名称", trigger: "change" }],
        number: [{ required: true, message: "请输入产品编号", trigger: "change" }],
      },
      previewVisible: false,
      previewImage: "",
      columnsBatch: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "批次编号",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "库存数量",
          dataIndex: "remain_quantity",
          key: "remain_quantity",
          scopedSlots: { customRender: "remain_quantity" },
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          key: "production_date",
          scopedSlots: { customRender: "production_date" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "80px",
        },
      ],
      curWarehouse: {},
      batchItems: [],
    };
  },
  computed: {
    enablePurchaseManage() {
      return this.$store.state.user.enablePurchaseManage;
    },
    enableSalesManage() {
      return this.$store.state.user.enableSalesManage;
    },
  },
  methods: {
    chooseBatch(item) {
      this.batchTitle = "管理批次-" + item.name;
      this.curWarehouse = item;
      if (item.batch_items) {
        this.batchItems = item.batch_items;
      } else {
        this.batchItems = [
          {
            key: -Date.now(),
            number: "N/A",
            remain_quantity: 0,
            production_date: null,
          },
        ];
      }
      this.batchVisible = true;
    },
    confirmChoosed() {
      let ifHasEmpty = false;
      let sumAmount = 0;
      this.batchItems.map((item) => {
        sumAmount += item.remain_quantity;
        if (!item.number || item.remain_quantity == undefined || item.remain_quantity == null) {
          ifHasEmpty = true;
        }
      });
      if (ifHasEmpty) {
        this.$message.warn("请将批次信息填写完整!");
        return;
      }
      let tmp = { ...this.curWarehouse, ...{ batch_items: this.batchItems, total_quantity: sumAmount } };
      this.warehouseItems = this.$functions.replaceItem(this.warehouseItems, tmp);
      this.batchVisible = false;
    },
    addLine() {
      const { batchItems } = this;
      const newData = {
        key: -Date.now(),
        number: "N/A",
        initial_quantity: 0,
        remain_quantity: 0,
        production_date: null,
      };
      this.batchItems = [...batchItems, newData];
    },
    removeLine(item) {
      this.batchItems = this.$functions.removeItemBatch(this.batchItems, item);
    },
    changeQuantity(value, item) {
      item["initial_quantity"] = value || 0;
      this.warehouseItems = this.$functions.replaceItem(this.warehouseItems, item);
    },
    changeQuantityBatch(e, item, pro) {
      if (pro === "number") {
        item[pro] = e.target.value;
      } else {
        item[pro] = e;
      }
      this.batchItems[item.key - 1] = item;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.$set(this.form, "image_items", fileList);
    },
    beforeUpload() {},
    handleCancel() {
      this.previewVisible = false;
    },
    confirm() {
      let image_items = this.form.image_items.map((item) => {
        return item.response.id;
      });
      let inventory_items = this.warehouseItems.map((item) => {
        return {
          warehouse: item.id,
          initial_quantity: item.initial_quantity,
          total_quantity: item.total_quantity,
          batch_items: this.form.enable_batch_control ? item.batch_items : [],
        };
      });
      let formatData = {
        ...this.form,
        ...{
          goods_images: image_items,
          inventory_items,
        },
      };

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? goodsInformationUpdate : goodsInformationCreate;
          func(formatData)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    // 'form.image_items': {
    //   handler(newVal) {
    //     if (Array.isArray(newVal)) {
    //       var images = newVal.map((file)=>{
    //         if (file.status) {
    //           if (file.status == 'done') {
    //             if (file.response && file.response.id) {
    //               return file.response.id;
    //             }
    //             if (file.uid) {
    //             return file.uid;
    //           }
    //           }
    //         }
    //       });
    //       this.$set(this.form,'images',images);
    //     }
    //   },
    //   deep: true
    // },
  },
};
</script>

<style scoped></style>
